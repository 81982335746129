<template>
  <DashboardWrapper>
        <div class="container mx-auto">
            <h2 class="text-white pb-4 mb-4 mx-4 sm:mx-0 uppercase text-xl font-extrabold">Alle coaches</h2>

            <div class="flex justify-end space-x-16">
                <router-link class="my-auto" :to="{ name: '' }">    
                    <div class="text-white">+ Coaches uitnodigen</div>
                </router-link>
                <SearchInput placeholder="Coach zoeken" theme="dark"/>
            </div>

            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(name)">
                                                <div>Naam</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(email)">
                                                <div>Email</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>
                                        
                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(team)">
                                                <div>Team</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(analyses)">
                                                <div>Analyses</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>

                                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <div class="flex items-center cursor-pointer" @click="sort(status)">
                                                <div>Aangemeld</div>
                                                <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                        </th>
                                    
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-500">


                                    <!-- Test row need to go in component -->
                                    <tr>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                            Albert de Jong                               
                                        </td>
                                         <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                            AlbertdJong@gmail.com
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                            Jongens U22-1
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                            3                                          
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                        <span class="px-3 py-2 text-xs font-medium rounded bg-scorelitred text-white">
                                            In afwachting
                                        </span>
                                        </td>
                                        <td align="right">
                                            <div class="flex justify-end pr-6">
                                                <svg class="h-6 w-6 mr-3 text-white transform transition-all duration-150 hover:scale-125 hover:text-gray-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                </svg>
                                                <svg class="h-6 w-6 text-white transform transition-all duration-150 hover:scale-125 hover:text-scorelitred cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </td>   
                                    </tr>
                              
                                    <!-- <td colspan="5" class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-300 font-medium text-center">
                                            Geen coaches
                                    </td> -->
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import SearchInput from "@/components/forms/SearchInput";

export default {
    name: "leden",
    components: {
        DashboardWrapper,
        SearchInput,
    }
}
</script>

<style>
</style>